<template>
  <div class="main">
    <div class="con_top">
      <div class="con_top_title">企业文化考核</div>
      <div class="con_top_msg">
        <span>考核填写说明</span>
        <span>1、请对以下同事进行认真评选，请勾选出符合本条企业文化的同事</span>
        <span>2、当所得分达到4分、5分时，需要有相应的事迹说明</span>
      </div>
    </div>
    <div class="culture">
      <div v-for="(item,index) in setting" :key="index" :class="{top_item:settingIndex==index}">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="content">
      <div v-for="(q_item,index) in (setting&&setting.length>0?setting[settingIndex].item:[])" v-show="index==settingItemIndex" :key="index" class="question">
        <span>{{ q_item.question }} ({{ q_item.sort }}分)</span>
      </div>
      <div class="con_list">
        <div v-for="(real_item,real_index) in checkStaff" :key="real_index" class="con_item">
          <van-checkbox v-model="real_item.case" :name="real_item" shape="square" checked-color="#ee0a24" class="check_box" @change="checkChange(real_item)">{{ real_item.employee_realname }}</van-checkbox>
          <!-- <div> -->
          <!-- <span>{{ real_item.employee_realname }}</span> -->
          <van-field
            v-show="real_item.showfield"
            v-model="real_item.caseText"
            class="input"
            rows="3"
            label=""
            type="textarea"
            placeholder="请输入事例"
            @click.stop="a"
          />
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="btn_group">
      <van-button v-show="!(settingIndex==0&&settingItemIndex==0)" class="btn" @click="lastStep">上一步</van-button>
      <van-button class="btn" @click="nextStep">下一步</van-button>
    </div>
  </div>
</template>

<script>
import { getAnswerNext, toChoseApi, getCulture } from '@/api/assessment'
import { Toast } from 'vant'
export default {
  data() {
    return {
      setting: [],
      settingIndex: 0,
      settingItemIndex: 0,
      checkStaff: [],
      selectedAllStaff: [],
      page: 0,
      exam_date: ''
    }
  },
  created() {
    this.isSubmint()
    this.exam_date = sessionStorage.getItem('DATE')
    const result = JSON.parse(sessionStorage.getItem('staffList'))
    this.selectedAllStaff = result.staff_list
    this.selectedAllStaff.forEach(e => {
      this.$set(e, 'showfield', false)
    })
  },
  activated() {
    this.isSubmint()
    if (sessionStorage.getItem('isAgain') === 1 || sessionStorage.getItem('isAgain') === '1') {
      this.settingIndex = 0
      this.settingItemIndex = 0
      this.page = 0
      this.exam_date = sessionStorage.getItem('DATE')
      const result = JSON.parse(sessionStorage.getItem('staffList'))
      this.getNextData()
      this.setting = result.setting
      this.selectedAllStaff = result.staff_list
      this.selectedAllStaff.forEach(e => {
        this.$set(e, 'showfield', false)
      })
    }
  },
  mounted() {
    this.getNextData()
  },
  methods: {
    isSubmint() {
      const date = new Date()
      const y = date.getMonth() === 0 ? date.getFullYear() - 1 : date.getFullYear()
      const m = date.getMonth() === 0 ? 12 : date.getMonth() < 10 ? '0' + date.getMonth() : date.getMonth()
      const params = {
        exam_date: y + '-' + m
      }
      getCulture(params).then(res => {
        if (res.errNo === 1004) {
          console.log('本人已答过当月文化考核')
          this.$router.push({
            path: '/assessment/assuccess'
          })
        }
      })
    },
    a() {},
    // 获取下一条数据
    getNextData(selected) {
      const params = {
        name: 'answer',
        page: this.page + 1,
        exam_date: this.exam_date,
        selected: selected ? JSON.stringify(selected) : ''
      }
      toChoseApi(params).then(res => {
        this.setting = res.result.setting
        this.checkStaff = res.result.staff_list
        const settingItem = this.setting[this.settingIndex]
        const topicItem = settingItem.item[this.settingItemIndex]
        this.checkStaff.forEach(e => {
          if (topicItem.sort >= 4) {
            if (e.case || e.case === 1) {
              this.$set(e, 'showfield', true)
            } else {
              this.$set(e, 'showfield', false)
            }
          } else {
            this.$set(e, 'showfield', false)
          }
          if (!e.caseText) {
            this.$set(e, 'caseText', '')
          }
        })
        console.log(this.checkStaff)
      })
    },
    checkChange(item) {
      const settingItem = this.setting[this.settingIndex]
      const topicItem = settingItem.item[this.settingItemIndex]
      if ((item.case || item.case === 1) && topicItem.sort >= 4) {
        item.showfield = true
      } else {
        item.showfield = false
      }
      console.log(item)
    },

    lastStep() {
      const params = {
        page: this.page,
        name: 'answer',
        exam_date: this.exam_date
      }
      toChoseApi(params).then(res => {
        this.page--
        if (!res) {
          this.$router.go(-1)
          return
        }
        // 默认勾选上一步选中
        if (this.settingItemIndex === 0) {
          this.settingIndex--
          this.settingItemIndex = this.setting[this.settingIndex].item.length
        }
        this.settingItemIndex--
        const settingItem = this.setting[this.settingIndex]
        const topicItem = settingItem.item[this.settingItemIndex]

        const staff_list = res.result.staff_list
        staff_list.forEach(e => {
          if (topicItem.sort >= 4) {
            if (e.case || e.case === 1) {
              this.$set(e, 'showfield', true)
            } else {
              this.$set(e, 'showfield', false)
            }
          } else {
            this.$set(e, 'showfield', false)
          }
          if (!e.caseText) {
            this.$set(e, 'caseText', '')
          }
        })

        this.checkStaff = staff_list
      })
    },

    nextStep() {
      console.log(this.checkStaff)
      const settingItem = this.setting[this.settingIndex]
      const topicItem = settingItem.item[this.settingItemIndex]
      const tmpSelected = []
      let continues = true
      console.log(this.checkStaff)
      if (this.checkStaff.length > 0) {
        this.checkStaff.forEach(e => {
          if (e.case || e.case === 1) {
            if (e.showfield && e.caseText.length < topicItem.mini_word_num) {
              continues = false
              return Toast(`字数最少${topicItem.mini_word_num}字`)
            }
            tmpSelected.push({
              employee_id: e.employee_id,
              employee_realname: e.employee_realname,
              caseText: e.caseText
            })
          }
        })
        if (!continues) {
          return
        }
      }

      this.page++
      const obj = {}
      const selected = tmpSelected.reduce((cur, next) => {
        obj[next.employee_id] ? '' : obj[next.employee_id] = true && cur.push(next)
        return cur
      }, [])
      console.log('selected', selected)
      const data = {
        name: settingItem.name,
        name_sort: settingItem.sort,
        question: topicItem.question,
        question_sort: topicItem.sort,
        score: topicItem.score,
        write_case: topicItem.write_case,
        mini_word_num: topicItem.mini_word_num,
        page: this.page,
        selected,
        staff_list: this.checkStaff,
        all_staff_list: this.selectedAllStaff
      }
      // sessionStorage.setItem('tmpStaff', JSON.stringify(this.answer))
      const params = {
        page: this.page,
        name: 'answer',
        data,
        exam_date: this.exam_date
      }
      getAnswerNext(params).then(res => {
        if (res.errNo === 0) {
          this.caseText = ''
          this.getNextData(selected)
        }
      })
      this.settingItemIndex++
      if (this.settingItemIndex >= this.setting[this.settingIndex].item.length && this.settingIndex < this.setting.length - 1) {
        this.settingIndex++
        this.settingItemIndex = 0
        this.checkStaff = this.selectedAllStaff
      } else if (this.settingItemIndex >= this.setting[this.settingIndex].item.length && this.settingIndex === this.setting.length - 1) {
        this.$router.push({ path: '/assessment/exhibition', query: { page: this.page }})
      }
    },

    oldnext() {
      const settingItem = this.setting[this.settingIndex]
      const topicItem = this.setting[this.settingIndex].item[this.itemIndex]
      let continues = true
      if (this.showCase) {
        if (this.answer.length > 0) {
          this.answer.forEach(v => {
            if (v.caseText.length < topicItem.mini_word_num) {
              Toast(`字数最少${topicItem.mini_word_num}字`)
              continues = false
              return
            }
          })
        }
        if (!continues) {
          return
        }
      }
      this.page++
      // const selected = []
      const tmpSelected = []
      this.answer.forEach(v => {
        tmpSelected.push({
          employee_id: v.employee_id,
          employee_realname: v.employee_realname,
          caseText: v.caseText
        })
        // selected.push({
        //   employ_id: v.employee_id,
        //   real_name: v.employee_realname,
        //   case: v.caseText
        // })
      })
      const obj = {}
      const selected = tmpSelected.reduce((cur, next) => {
        obj[next.employee_id] ? '' : obj[next.employee_id] = true && cur.push(next)
        return cur
      }, [])

      const data = {
        name: settingItem.name,
        name_sort: settingItem.sort,
        question: topicItem.question,
        question_sort: topicItem.sort,
        score: topicItem.score,
        write_case: topicItem.write_case,
        mini_word_num: topicItem.mini_word_num,
        page: this.page,
        selected,
        staff_list: this.checkStaff,
        all_staff_list: this.selectedAllStaff
      }
      // console.log(data)
      sessionStorage.setItem('tmpStaff', JSON.stringify(this.answer))
      const params = {
        page: this.page,
        name: 'answer',
        data,
        exam_date: this.exam_date
      }
      getAnswerNext(params).then(res => {
        if (res.errNo === 0) {
          this.getNextData(settingItem, this.answer)
          this.answer = []
        }
      })
      this.itemIndex++
      if (this.itemIndex >= this.setting[this.settingIndex].item.length && this.settingIndex < this.setting.length - 1) {
        this.settingIndex++
        this.itemIndex = 0
        this.checkStaff = this.selectedAllStaff
      } else if (this.itemIndex >= this.setting[this.settingIndex].item.length && this.settingIndex === this.setting.length - 1) {
        this.$router.push({ path: '/assessment/exhibition', query: { page: this.page }})
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.con_top{
  width: 100%;
  margin-bottom: 40px;
  .con_top_title{
    width: 100%;
    padding: 20px 0;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
  }
  .con_top_msg{
    padding: 10px;
    width: calc(100% - 20px);
    background-color: #e5e5e5;
    span{
      display: block;
      width: 100%;
    }
  }
}
.culture{
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  div{
    width: 23%;
    height: 80px;
    text-align: center;
    line-height: 80px;
    span {
      font-size: 28px;
      padding-bottom: 20px;
    }
  }
}
.top_item{
  background-color: #ee2e2e;
  color: #fff;
}
.content{
  padding: 20px 30px;
  // background-color: #ddd;
  .question{
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 10px;
  }
}
.con_item{
  display: flex;
  padding: 20px 0;
  width: 100%;
}
.check_box{
  min-height: 40px;
  display: flex;
  align-items: flex-start;
  width: 140px;
  /deep/.van-checkbox__icon .van-icon{
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 6px;
    margin-top: 4px;
  }
}
.input{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
  width: calc(100% - 160px);
}

.btn_group{
  margin-top: 20px;
  text-align: center;
  padding-bottom: 40px;
  .btn{
    color: #333;
    width: 200px;
    margin-right: 30px;
    height: 70px;
    background-color: #ddd;
    border: 0;
  }
}
</style>
